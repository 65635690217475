<template>
    <div>
        点播屏, 准备移除
    </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    initPageData (searchBean) {
      // 更新地图显示站点和线路
      const params = {
        data: [],
        checkedSchedules: searchBean.schedules,
        assetIds: searchBean.assetIds,
        condition: {}
      }
      this.$emit('on-updated-map', 'full', params)
    }
  }
}
</script>
